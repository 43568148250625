<template>
    <div class="stakewait">
      <!-- notification start -->
      <div class="marquee">
        <!-- <Marquee :duration="15">{{ $t("network.falconv4") }}</Marquee> -->
        <!-- <Marquee :duration="15">{{ $t("menu.LogiV3tips") }}</Marquee> -->
      </div>
      <!-- notification end -->
       ido3
      <div class="coinInfomation">
        <div class="coinImg"><img :src="falocn" class="dailogo" /></div>
        <div class="coinInfo">{{ $t("menu.Ido") }}</div>
  
      </div>
      <div v-if="timershow">
        <div class="nowNumber" v-if="finishshow">{{ $t("ido.end1") }}</div>
        <div class="nowNumber" v-if="finishshow == false">{{ $t("ido.endTimes") }}</div>
        <div class="endTime" v-if="finishshow == false">{{ countdown }}</div>
      </div>
      <div v-else>
        <div class="nowNumber">{{ $t("ido.needStart") }}</div>
        <div class="endTime">{{ countdown }}</div>
      </div>
  
  
  
      <Divider style="background: rgba(142, 200, 243, 0.1)" />
      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("ido.select") }}:</div>
        <div class="ownMoney">
  
        </div>
      </div>
  
      <div class="StakeAuthor">
        <div class="selectstyle">
          <select v-model="selected" id="dropdown">
            <option v-for="option in options" :value="option.value" :key="option.value" style="color: #000;">
              {{ option.text }}
            </option>
          </select>
        </div>
      </div>
  
      <!-- <button @click="usersLeave()" class="tbnStyle">{{ $t("ido.getmax") }}</button> -->
  
      <div class="nowPrice">1 Falcon = 0.05 DAI</div>
      <div class="ownwalletInfo">
        <div class="ownTips">{{ $t("stake.Balance") }}:</div>
        <div class="ownMoney">
          {{ formattedDaiOwnMoney }}<img class="daimi" :src="daimni" />
        </div>
      </div>
      <!-- <div class="inputInfo">
        <input class="inputStyle" type="number" placeholder="0.00" v-model="value1" @input="oninput" />
      </div> -->
    <div class="inputInfo">
      <input style="width: 80%;flex: 3;" class="inputStyle" type="number" placeholder="0.00" v-model="value1"
        @input="oninput" />
      <button style="margin-top: 0;width: 100px;" @click="(value1 = formattedDaiOwnMoney), oninput()"
        class="tbnStyle ripple">
        {{ $t("network.all") }}
      </button>
    </div>


    <div v-if="value1 > 0 && subscriptionthso">
      <button v-if="empowerbutton" class="tbnStyle" @click="ido()">{{ $t("ido.nowbuy") }}</button>
      <button v-if="empowerbutton == false" @click="Approve()" class="tbnStyle ">{{ $t("ido.Authorization")
        }}</button>
    </div>
    <button v-else-if="subscriptionthso == false" class="tbnStyle tbnStyle_type">{{ $t("ido.okSubscribe")
      }}</button>
    <button v-else class="tbnStyle tbnStyle_type">{{ $t("ido.nowbuy") }}</button>




    <div><img class="bStyle" :src="stylea" alt="" /></div>
    <!--loading start-->
    <div v-if="isloading" class="loading">
      <img :src="loadingimg" class="loadimg" />
    </div>
    <!--loading end-->
  </div>
</template>

<style lang="less" scoped>
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  background-image: radial-gradient(circle, #666 10%, transparent 10.01%);
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(10, 10);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.stakewait {
  border-radius: 10px;
  box-shadow: 0px 0px 0px 0.4px rgb(80, 77, 77) inset;
  background-image: linear-gradient(133deg,
      rgba(7, 110, 150, 0.3),
      rgba(132, 2, 72, 0.6));
  box-sizing: border-box;
  width: 330px;
  color: white;
  padding: 40px 20px 20px 20px;
  position: relative;

  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: solid 1px rgba(70, 70, 70, 0.581);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    -moz-backdrop-filter: blur(3px);
    -o-backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.1);
    opacity: 0.9;
    color: #81c0f1;
    z-index: 10000 !important;
    top: 0;
    left: 0;
    animation: fadeIn 0.3s ease-out forwards;

    .loadimg {
      width: 118px;
      height: 118px;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .bStyle {
    width: 290px;
    height: 76px;
  }

  .currP {
    margin-bottom: 20px;
  }

  .autoProcess {
    display: flex;
    justify-content: flex-start;

    .a1 {
      margin-right: 8px;
      height: 15px;
    }

    .a1 input {
      width: 15px;
      height: 15px;
    }

    .a2 {
      height: 15px;
      position: relative;
      width: 100%;

      span {
        position: absolute;
        top: -2px;
      }
    }

    // input {
    //     width: 15px;
    //     height: 15px;
    //     border-radius: 5px;
    // }

    // span {
    //     position: absolute;
    //     top: 0px;
    //     left: 25px;
    // }
  }

  .tbnStyle {

    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-top: 20px;
    border-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 18px;
    color: rgb(2, 0, 0);
    font-size: 20px;
    background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
  }

  .tbnStyle_type {
    background: #ddd;
  }


  .nowPrice {
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .StakeAuthor {




    .selectstyle #dropdown {

      border: 3px solid #81c0f1;
      height: 53px;
      box-sizing: border-box;
      border-radius: 8px;
      margin: 10px 0;
      width: 100%;
      background-color: transparent;
      color: white;
      font-size: 15px;

      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;


      background-size: 20px;
      padding: 10px;

      background: url("../assets/img/dw.svg") no-repeat right 10px center;
      font-size: 14px;

    }



  }

  .hr1 {
    border: none;
    border-top: 1px solid rgba(142, 200, 243, 0.3);
    /* 蓝色线条 */
  }

  .tvl {
    font-size: 10px;
    margin: 5px 0 5px 0;

    span {
      font-weight: 800;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .StakeInfo {
    display: flex;
    margin-bottom: 10px;

    .StakeResult {
      flex: 1;
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      position: relative;
      top: 6px;
    }

    .StakeMoney {
      text-align: right;
      font-size: 20px;
      font-weight: 600;

      .daimi {
        margin-left: 5px;
        width: 14px;
        height: 12px;
      }
    }
  }

  .inputInfo {
    border: 3px solid #81c0f1;
    height: 53px;
    box-sizing: border-box;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;

    .inputStyle {
      border: none;
      outline: none;
      flex: 1;
      text-align: center;
      height: 100%;
      font-size: 20px;
      padding: 0 10px;
      color: #81c0f1;
      background-color: transparent;
      box-sizing: border-box;
      border-radius: 8px;
    }

    .tbnStyle {
      flex: 1;
      color: rgb(72, 2, 64);
      border: none;
      outline: none;
      font-size: 20px;
      border-radius: 0px 5px 5px 0px;
      cursor: pointer;
      background: url("../assets/img/Gradient.svg") no-repeat center center/cover;
    }
  }

  .ownwalletInfo {
    font-family: "Poppins", sans-serif;
    display: flex;

    .ownTips {
      font-size: 18px;
      font-weight: 600;
      flex: 1;
    }

    .ownMoney {
      font-size: 20px;

      img.daimi {
        width: 14px;
        height: 12px;
        margin-left: 5px;
      }
    }
  }

  .marquee {
    margin-bottom: 15px;
    color: #81c0f1;
  }

  .nowNumber {
    text-align: center;
  }

  .endTime {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
    color: #08ebeb;
    margin-bottom: 10px;
  }

  .coinInfomation {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .coinImg {
      width: 29px;
      height: 29px;

      .dailogo {
        width: 29px;
        height: 29px;
      }
    }

    .coinInfo {
      font-size: 18px;
      font-family: "Poppins", sans-serif;
      position: relative;
      top: 0px;
      left: 10px;
      font-weight: bold;
    }
  }
}
</style>
<script>
import ProgressBar from "@/components/ProgressBar.vue";
import Marquee from "@/components/Marquee.vue";
import web3Utils from "@/utils/web3Utils.js";
import dai from "@/assets/img/DaiStablecoin.svg";
import loadingimg from "@/assets/img/loading.svg";
import FG1 from "@/assets/img/FG1.svg";
import D1 from "@/assets/img/d1.svg";
import D2 from "@/assets/img/d2.svg";
import D3 from "@/assets/img/d3.svg";
import falocn from "@/assets/img/falocn.svg";
import daimni from "@/assets/img/daimini.svg";
import stylea from "@/assets/img/stylea.png";
import contract from "@/plugins/contract.js";
import siteapi from "@/json/siteapi.json";
import Plugens from "@/plugins/index";
import Falocnn from "@/plugins/Falocnn.js";
import idojs2 from "@/plugins/idojs2.js";
import Web3 from "web3";
import axios from "axios";
import sign from "@/plugins/sign.js";

export default {
  data() {
    return {
      isButtonDisabled: true,
      depositDisabled: true,
      typeApprove: 0,
      typedeposit: 0,
      dai: dai,
      FG1: FG1,
      D1: D1,
      D2: D2,
      D3: D3,
      falocn: falocn,
      daimni: daimni,
      loadingimg: loadingimg,
      daiValue: "100",
      daiOwnMoney: 0,
      stylea: stylea,
      stakedMoney: 0,
      currentProgress: 0,
      usersdata: {},
      sitedata: {},
      addresscode: "",
      empowerbutton: true,
      needinvest: "",
      Minimal: 0,
      isloading: false,
      falconAddresstype: true,
      isOpentype: false,
      flashAddresstype: true,
      returndatadata: "",
      promptvalue: "",
      honorLeave: "",
      selected: 0,
      options: [
        { text: this.$t("ido.flashmonkey"), value: "1" },
        { text: this.$t("ido.flashdragon"), value: "2" },
        { text: this.$t("ido.powermonger"), value: "3" },
        { text: this.$t("ido.FalconA"), value: "4" },
        { text: this.$t("ido.FalconF"), value: "5" },
        { text: this.$t("ido.other"), value: "6" },
      ],
      isOpenshow: false,
      teamInvestAmount: 0,
      value1: '',
      idoLimitsvalue: 0,
      participatedshow: false,
      endTime: null,
      countdown: '',
      timer: null,
      timershow: false,
      finishshow: false,
      subscriptionthso: false,
      totalAmount: 0

    };
  },
  unmounted() {
    clearInterval(this.timer)
  },
  beforeDestroy() {
    clearInterval(this.timer)
  },
  created() {
    // 1719493200 

    const date = new Date('2024-06-27 21:00:00');

    console.log(date.getTime())
    // 用法示例

    const storedEndTime = date.getTime() + (5 * 24 * 60 * 60) * 1000;
    console.log(storedEndTime, 'aaaaaaaaaaaaa')
    // localStorage.getItem('endTime');
    if (storedEndTime) {
      this.endTime = parseInt(storedEndTime, 10);
    } else {
      this.startCountdown(5 * 24 * 60 * 60); // 5天倒计时
    }
    this.updateCountdown(date.getTime());
    // setInterval(this.updateCountdown, 1000);
    this.timer = setInterval(() => {
      //需要定时执行的代码
      this.updateCountdown(date.getTime())
    }, 1000)
  },
  components: {

    //ProgressBar,
  },

  async mounted() {

    // Determine whether the wallet is connected
    if (typeof window.ethereum !== "undefined") {
      //console.log("Connected wallet");

      // Async obtaining network information
      window.ethereum
        .request({ method: "eth_chainId" })
        .then((chainId) => {
          // is Polygon?
          if (chainId === "0x89") {
            // console.log("The current network is a Polygon chain");
            // this.$router.push({path:'/EvStake'});
          } else {
            //  console.log("The current network is not Polygon chain");
            this.$router.push({ path: "/" });
            return;
          }
        })
        .catch((error) => {
          console.error(
            "Failed to obtain network information. Procedure:",
            error
          );
        });
    } else {
      //console.log("Unconnected wallet");
    }
    this.sitedata = siteapi;
    const currentAddress = await web3Utils.connectWallet();
    // console.log(currentAddress);
    this.addresscode = currentAddress;
    if (currentAddress) {
      this.daiOwnMoney = parseFloat(
        await web3Utils.getDaiBalance(this.addresscode)
      );

      console.log(this.daiOwnMoney.toFixed(6), 'daiOwnMoneydaiOwnMoneydaiOwnMoney');

      this.signtype();

    }
  },
  methods: {

    startCountdown(duration) {
      this.endTime = Date.now() + duration * 1000;
      localStorage.setItem('endTime', this.endTime);
    },
    updateCountdown(item) {
      const now = Date.now();
      var remainingTime
      if (this.timershow == false) {
        // console.log(now)
        // console.log(item)
        if (now > item) {
          this.timershow = true
        }
        remainingTime = Math.max((item - now) / 1000, 0);
      } else {
        remainingTime = Math.max((this.endTime - now) / 1000, 0);
        if (this.endTime - now <= 0) {
          console.log('结束')
          this.finishshow = true
          clearInterval(this.timer)
        }
      }



      const days = Math.floor(remainingTime / (24 * 60 * 60));
      const hours = Math.floor((remainingTime % (24 * 60 * 60)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);
      if (this.pad(hours) == null) {
        this.countdown = 0
        return
      }

      this.countdown = `${days}D ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    },
    pad(number) {
      return number.toString().padStart(2, '0');
    },

    // 签名验证
    async signtype() {
      this.isloading = true;
      await sign.getauthorization(this.addresscode).then((res) => {

        if (res.code == 0) {
          this.users()
          this.vestingSchedules()

          this.exchangeallowance()
        }
        this.isloading = false;
      });
    },
    oninput() {

      console.log(this.value1)
      var number = 10000


      if (this.value1 > number * 1) {
        this.value1 = number
      }

    },
    // 授权代合约授权ido合约
    // User information
    async users() {
      //console.log(this.sitedata);
      let _decimals = 18;
      let ContractAddress;
      //console.log("User information");
      ContractAddress = this.sitedata.aave_token.address;
      await contract
        .users(
          this.addresscode, //Current user address
          ContractAddress, //Call contract
          _decimals
        )
        .then((res) => {
          console.log(res.data, 'users')
          this.honorLeave = res.data.level
          this.teamInvestAmount = res.data.teamInvestAmount * 1
          //console.log(res, "User information");
          this.usersdata = res.data;
          this.stakedMoney = res.data.investAmount * 1;
          //console.log(this.stakedMoney);
          //const total = 14000;
          //this.currentProgress = (this.stakedMoney / total) * 100;
        });
    },
    async vestingSchedules() {

let _decimals = 18;
let ContractAddress;
ContractAddress = this.sitedata.ido_token2.address;
await idojs2.vestingSchedules(
  this.addresscode,
  ContractAddress,
  _decimals,
).then((res) => {
  console.log(res, "vestingSchedules");
  this.totalAmount=res.data.totalAmount
  this.maxIDOAmount()

//   this.isOpenshow = res.data
});
},
async maxIDOAmount() {

let _decimals = 18;
let ContractAddress;
ContractAddress = this.sitedata.ido_token2.address;
await idojs2.maxIDOAmount(
  this.addresscode,
  ContractAddress,
  _decimals,
).then((res) => {
  console.log(res, "maxIDOAmount",this.totalAmount*1<=res.data*1);
  if(this.totalAmount*1<=res.data*1){
    console.log(1111111111111111111111)
    this.subscriptionthso=true
  }else{
    this.subscriptionthso=false
  }
//   this.isOpenshow = res.data
});
},
      async usersLeave() {
        console.log(this.selected);
        let _decimals = 18;
        let ContractAddress;
        console.log(this.selected == 'other')
        if (this.selected == 'other') {
          console.log(this.options[0].value)
          ContractAddress = this.options[0].value
        } else {
          ContractAddress = this.selected;
        }
        //console.log("User information");
  
        await contract
          .users(
            this.addresscode, //Current user address
            ContractAddress, //Call contract
            _decimals
          )
          .then((res) => {
            console.log(res.data, 'usersLeave')
            this.honorLeave = res.data.level
            this.teamInvestAmount = res.data.teamInvestAmount * 1
            this.usersdata = res.data;
            this.stakedMoney = res.data.investAmount * 1;
            this.idoLimits()
          });
      },
      async idoLimits() {
        console.log(this.selected)
        this.value1 = 0
        var selected
        if (this.selected == 'other') {
          selected = this.options[1].value
        } else {
          selected = this.selected;
        }
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.ido_token2.address;
        try {
          await idojs2.idoLimits(
            this.addresscode,
            ContractAddress,
            _decimals,
            selected,
            this.honorLeave
          ).then((res) => {
            console.log(res, "idoLimits");
            if (res.code == 0) {
              this.$Notice.success({
                title: this.$t("ido.getscusse"),
              });
            } else {
              this.$Notice.warning({
                title: this.$t("ido.geterror"),
              });
            }
  
            this.idoLimitsvalue = res.data
            if (this.teamInvestAmount > 0) {
              this.value1 = this.idoLimitsvalue
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("ido.geterror"),
          });
          // console.log(error);
        }
      },
     
      
      // 授权
      async Approve() {
        this.isloading = true; // close loading
        let _decimals = 18;
        let Address;
        let spender;
        Address = this.sitedata.dai_token.address;
        spender = this.sitedata.ido_token2.address;
        this.typeApprove = 1; // Approveing
        try {
          await Plugens.approveTokens(
            Address, //
            this.daiValue, // DAI number
            this.addresscode,
            spender,
            _decimals
          ).then((res) => {
            // console.log(res);
            this.typeApprove = 0;
            if (res.code == 0) {
              this.typeApprove = 0;
              this.$Notice.success({
                title: this.$t("stake.AuthorSuccessful"),
                // desc: this.$t("stake.AuthorSuccessful"), // "Author Successful",
              });
              this.isloading = false; // close loading
              this.empowerbutton = true;
  
            } else {
  
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("stake.AuthorFailed")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("stake.AuthorFailed"),
            // desc: this.$t("stake.AuthorFailed"),
          });
          // console.log(error);
        }
      },
      // 是否需要授权
      async exchangeallowance() {
        let _decimals = 18;
        let spender;
        let Address;
        Address = this.sitedata.dai_token.address;
        spender = this.sitedata.ido_token2.address;
        await Plugens.allowancetoken(
          Address,
          0,
          this.addresscode,
          spender,
          _decimals
        ).then((result) => {
          console.log(result, "授权数量");
          if (result.data * 1 > 0) {
            this.empowerbutton = true;
          } else {
            this.empowerbutton = false;
          }
  
        });
      },
      // 认购
      async pido() {
        this.isloading = true
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.ido_token2.address;
        try {
          await idojs2.pido(
            this.addresscode,
            ContractAddress,
            _decimals,
            this.selected,
            this.honorLeave,
            this.value1
          ).then((res) => {
            console.log(res, "pido");
            if (res.code == 0) {
              this.isloading = false;
             
           
            
              this.exchangeallowance()
              // this._flashAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("network.flashloansNo")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
  
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
      // 认购
      async ido() {
        // if(this.daiOwnMoney<this.value1){
        //   this.$Notice.warning({
        //           title: this.$t("Withdraw.WithdrawInfo2"),
        //         });
        //         return
        // }
        var selected=this.selected
        if(selected==0){
            this.$Notice.warning({
                  title: this.$t("ido.select"),
                });
                return
        }
        this.isloading = true
        let _decimals = 18;
        let ContractAddress;
        ContractAddress = this.sitedata.ido_token2.address;
        
        // if (this.selected == 'other') {
        //   selected = this.options[0].value
        // } else {
        //   selected = this.selected;
        // }
        console.log(selected)
        try {
          await idojs2.ido(
            this.addresscode,
            ContractAddress,
            _decimals,
            selected,
            this.value1
          ).then((res) => {
            console.log(res, "ido");
            if (res.code == 0) {
              this.isloading = false;
             
            
              
              this.exchangeallowance()
              // this._flashAddress()
              this.$Notice.success({
                title: this.$t("network.flashloansOK"),
                //desc: this.$t("stake.StakeSuccessful"), // StakeSuccessful
              });
            } else {
  
              this.isloading = false;
              if (res.code < 1) {
                this.$Notice.warning({
                  title: res.error.message
                });
              } else {
                this.$Notice.warning({
                  title: this.$t("network.flashloansNo")
                  // desc: this.$t("stake.StakeFailed"), //"StakeFailed",
                });
              }
  
            }
          });
        } catch (error) {
          // this.typeApprove = 1;
          this.isloading = false;
          this.$Notice.warning({
            title: this.$t("network.flashloansNo"),
            //desc: this.$t("stake.AuthorFailed"), //"AuthorFailed",
          });
          //console.log(error);
        }
      },
  
  
  
  
    },
    computed: {
      formattedDaiOwnMoney() {
        if (!this.daiOwnMoney) return "0.0000";
  
        const multipliedNumber = this.daiOwnMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //    console.log(result);
        //    console.log(this.daiOwnMoney);
        let value = parseFloat(result);
  
  
        return value;
      },
      formattedDaiOwnMoney2() {
        if (!this.stakedMoney) return "0.0000";
        const multipliedNumber = this.stakedMoney * 100000;
        const truncatedNumber = Math.trunc(multipliedNumber);
        const result = truncatedNumber / 100000;
        //console.log(result);
        //     console.log(this.stakedMoney);
        let value = parseFloat(result);
        //console.log(value)
        return value;
      },
    },
  };
  </script>
  