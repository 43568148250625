const pt = {
  network: {
    tips: `A rede selecionada não é suportada atualmente!
    Por favor, selecione a rede Matic (Polygon)!`,
    ev: `Por favor, verifique se instalou algum software de carteira, como MetaMask, 
    Trust Wallet, imToken ou TokenPocket.`,
    copyight: "FlashDragon",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Aviso aos Investidores: Este Aplicativo Distribuído (DApp) apresenta riscos de investimento incertos. Políticas nacionais e regulamentações podem exercer controle e limitações estritas sobre a tecnologia blockchain e investimentos relacionados. Adicionalmente, o preço das moedas digitais é extremamente volátil, podendo fazer com que os investidores enfrentem riscos significativos de investimento. Antes de participar de qualquer investimento, os investidores devem entender e avaliar completamente os riscos envolvidos e tomar decisões de maneira cautelosa. Fortemente aconselhamos os investidores a investirem racionalmente e a evitar seguir tendências cegamente, prevenindo assim perdas financeiras irreversíveis.`,
    protocol: `Este Aplicativo Distribuído (DApp) apresenta riscos de investimento incertos. Antes de usar, por favor, leia atentamente e compreenda totalmente os avisos de risco e a política de privacidade.`,
    more: "Detalhes",
    media: "Mídias Sociais Relacionadas",
    stake:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    withdraw:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Reinvest:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    Getreward:
      "Abertura global em 6 de novembro de 2023, siga-nos no Telegram e Twitter!",
    linkwallet: " Conectar Carteira",
    notification: "Esta funcionalidade será lançada em breve! Fique atento às notificações da comunidade",
    flashloansOK:'Inicialização bem-sucedida! ',
    flashloansNo:'Inicialização falhou!',
    flashloansOnce:'Parabéns por receber uma chance de experiência!',
    flashloansError:'Desculpe, a oportunidade de experiência foi utilizada!',
    falocnerror:'Falcon™ V2 Não autorizado para teste!',
    falocninfo:'Falcon™ V2 para jogadores aprovados no nível V3! Atualmente em fase de teste público!!',
    falconv4:'O cluster de arbitragem Falcon™V4 está em teste beta (28000 vezes/dia)!'
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  top: {
    Account: "Conta",
  },
  swap:{
    Swap:"Troca",
    paynameA:"Pagamento",
    paynameB:"Recebimento",
    Approve:"Autorização",
    launchSwap:"Iniciar troca",
    recommend:"Recomendação",
    swapError:"Erro de troca",
    swapNetwork:"Erro de rede",
  },
  stake: {
    Balance: "Seu saldo",
    Total: "Aposta total",
    Appoove: "Aprovar",
    Stake: "Aposta",
    Appooveing: "Autorizando",
    AuthorSuccessful: "Autorização Bem-sucedida",
    AuthorFailed: "Falha na Autorização",
    Stakeing: "Depositando",
    StakeSuccessful: "Depósito Bem-sucedido",
    StakeFailed: "Falha no Depósito",
    StakeInfo: "Não pode ser Menor que:",
  },
  menu: {
    Home: "Início",
    Language: "Idioma",
    Document: "Livro Branco",
    Contract: "Contrato",
    Watch: "Assistir",
    Coming: "Em breve",
    EvDao: "FM-DAO",
    Flashloan: "Iniciar Flashloan",
    Developer:"Desenvolvedor",
    Launch:"Launch",
    Falcon:"Falcon™",
    xNumber:"Restante",
    xTips:"Processamento automático",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    Market:"Market",
    LogiV3tips:"O teste público global do Falcon™ V3 começa (pedidos diários de 1000~7000), é necessária autorização para os contratos de arbitragem correspondentes!",
    Appoove2:"Auto",
        Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Lucro",
  },
  ido:{
    select: "Selecionar comunidade",
    flashmonkey: "Macaco Flash",
    flashdragon: "Dragão Flash",
    fashFalcon: "Falcão",
    powermonger: "Poder Monger",
    fashfalcon2: "FalconV2",
    other: "Outro",
    Getmax: "Get Maximum",
    nowbuy: "Subscribe Now",
    mynumber: "My Falcon",
    myrelease: "Released",
    waitrelease: "Waiting for Release",
    get: "Withdraw",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organization",
    endTimes:'First Round Countdown',
    getmax:'Get Maximum',
    Authorization:"Authorization",
    Subscribe:"Subscribe Now",
    okSubscribe:"Subscribed",
    message:"Node Error, Please Change Node",
    end1:"First Round Ended",
    needStart:"Countdown to Start",
    getscusse:"Retrieval Successful",
    geterror:"Retrieval Failed"

  },
  Withdraw: {
    Withdraw: "Retirar",
    Withdrawable: "Retirável",
    Earnings: "Ganhos",
    Brokerage: "Quantidade de corretagem",
    Reinvest: "Reinvestir",
    Getreward: "Obter recompensa",
    Processing: "Em processamento",
    unsuccessfule: "Não foi bem-sucedido",
    successful: "Bem-sucedido!",
    WithdrawInfo1: "Por favor, insira a quantidade de retirada",
    WithdrawInfo2: "quantidade insuficiente",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Aposta da equipe",
    leavel: "Nível",
    Team: "Equipe",
    Bind: "Vincular relação",
    info: "O sistema pausou temporariamente a vinculação, por favor aguarde a abertura.",
    Binding: "Vinculação em Andamento",
    BindingSuccessful: "Vinculação Bem-sucedida",
    BindingFailed: "Falha na Vinculação",
    Bindinfo: "Por favor, insira o endereço da carteira do convidante",
  },
  Share: {
    Invitelink: "Link de convite",
    Intips:
      "Os convites Dapp não estão disponíveis no momento. Aguarde as atualizações mais recentes da comunidade.",
    copyTitle: "Link de convite",
    copyText: "Link de convite copiado com sucesso!",
  },
  Contract: {
    contractaddress: "Endereço do contrato",
    Copylink: "Polygonscan",
    CopylinkText: "Ir para polygonscan!",
    copyCommnad: "Copiar Link",
  },
  Wathch: {
    watchonlywallet: "Carteira somente para assistir",
    addwallet: "Adicionar",
    add: "Adicionar",
    Leavel: "Nível",
    share: "Compartilhar",
    fnOpen:
      "Esta carteira ainda não foi convidada para participar desta funcionalidade!",
      nullString: "Por favor, insira o endereço da carteira!",
      addok:"Adicionado com sucesso",
      addError:"Falha ao adicionar",
      addisExists:"Este endereço já existe",
      addlist:"Lista de Observação",
  },
};
export default pt;
