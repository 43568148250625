import { render, staticRenderFns } from "./FalconIdo4.vue?vue&type=template&id=33bb6d8f&scoped=true&"
import script from "./FalconIdo4.vue?vue&type=script&lang=js&"
export * from "./FalconIdo4.vue?vue&type=script&lang=js&"
import style0 from "./FalconIdo4.vue?vue&type=style&index=0&id=33bb6d8f&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.2_2c66e885b17cbecb4aa951ec806096ab/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bb6d8f",
  null
  
)

export default component.exports