const de = {
  network: {
    tips: `Das ausgewählte Netzwerk wird derzeit nicht unterstützt!
    Bitte wählen Sie das Matic (Polygon) Netzwerk!`,
    ev: "Bitte überprüfen Sie, ob Sie Wallet-Software wie MetaMask, Trust Wallet, imToken oder TokenPocket installiert haben.",
    copyight: "FlashDragon",
    all: "Max",
    bolck: "Dai Stablecoin",
    law: `Anlegerhinweis: Diese dezentrale Anwendung (DApp) birgt ungewisse Anlagerisiken. Nationale Richtlinien und gesetzliche Vorschriften könnten Blockchain-Technologie und damit verbundene Anlagen streng kontrollieren und beschränken. Außerdem sind die Preise von digitalen Währungen hochvolatil, was erhebliche Anlagerisiken für die Anleger mit sich bringt. Anleger sollten vor der Anlagebeteiligung die Risiken vollständig verstehen und bewerten und vorsichtig entscheiden. Wir raten dringend dazu, vernünftig zu investieren und nicht blind der Masse zu folgen, um irreversible finanzielle Verluste zu vermeiden.`,
    protocol: `Diese dezentrale Anwendung (DApp) birgt ungewisse Anlagerisiken. Bitte lesen und verstehen Sie vor der Benutzung die Risikowarnungen und Datenschutzrichtlinien gründlich.`,
    more: "Mehr",
    media: "Verwandte soziale Medien",
    stake:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    withdraw:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Reinvest:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    Getreward:
      "Weltweite Eröffnung am 6. November 2023, folgen Sie uns auf Telegram und Twitter!",
    notification: "Diese Funktion wird bald verfügbar sein! Achten Sie auf Community-Benachrichtigungen",
    flashloansOK:'Start erfolgreich!',
    flashloansNo:'Start fehlgeschlagen!',
    flashloansOnce:'Herzlichen Glückwunsch, Sie haben einmal die Chance zum Ausprobieren erhalten!',
    flashloansError:'Entschuldigung, die Gelegenheit zum Ausprobieren wurde bereits genutzt!',
    falocnerror:'Falcon™ V2 Testberechtigung nicht erteilt!',
    falocninfo:'Falcon™ V2 für Spieler, die auf V3-Niveau genehmigt wurden! Derzeit in der öffentlichen Testphase!',
    falconv4:'Das Falcon™V4 Arbitrage-Cluster befindet sich im Betatest (28000 Mal/Tag)!'
  },
  leavel: {
    0:"V0",
    1: "V1",
    2: "V2",
    3: "V3",
    4: "V4",
    5: "V5",
    6: "V6",
    7: "V7",
  },
  top: {
    Account: "Konto",
  },
  swap:{
    Swap:"Austausch",
    paynameA:"Zahlung",
    paynameB:"Zahlungseingang",
    Approve:"Autorisierung",
    launchSwap:"Austausch starten",
    recommend:"Empfehlung",
    swapError:"Austauschfehler",
    swapNetwork:"Netzwerkfehler",
  },
  stake: {
    Balance: "Ihr Guthaben",
    Total: "Gesamt-Stake",
    Appoove: "Genehmigen",
    Stake: "Stake",
    Appooveing: "Autorisierung",
    AuthorSuccessful: "Autorisierung Erfolgreich",
    AuthorFailed: "Autorisierung Gescheitert",
    Stakeing: "Einzahlung",
    StakeSuccessful: "Einzahlung Erfolgreich",
    StakeFailed: "Einzahlung Gescheitert",
    StakeInfo: "Darf nicht Weniger als Sein:",
  },
  menu: {
    Home: "Startseite",
    Language: "Sprache",
    Document: "Weißbuch",
    Contract: "Vertrag",
    Watch: "Beobachten",
    Coming: "Demnächst",
    EvDao: "BULLDAO",
    Falcon:"Falcon™",
    Flashloan: "Flashloan starten",
    Developer:"Entwicklerdokumente",
    Launch:"Launch",
    xNumber:"Verbleibend",
    xTips:"Automatische Verarbeitung",
    LogiV2:"Falcon™ V2",
    LogiV3:"Falcon™ V3",
    LogiV4:"Falcon™ V4",
    LogiV3tips:"Falcon™ V3 globales öffentliches Testing beginnt (tägliche Anfragen 1000~7000), Genehmigung für die entsprechenden Arbitrage-Verträge erforderlich!",
    Appoove2:"Auto",
    Market:"Market",
    Ido:"Falcon IDO",
    myIdo:"My Falcon",
    interest:"Gewinn",
    
  },
  ido:{
    select: "Community auswählen",
    flashmonkey: "Flash Affe",
    flashdragon: "Flash Drache",
    fashFalcon: "Falke",
    powermonger: "Power Monger",
    fashfalcon2: "FalconV2",
    other: "Andere",
    Getmax: "Maximalen",
    nowbuy: "Jetzt abonnieren",
    mynumber: "Mein Abonnement Gesamt",
    myrelease: "Freigegeben",
    waitrelease: "Warten auf Veröffentlichung",
    get: "Abheben",
    FalconF: "FlashFalcon(F)",
    FalconA: "FlashFalcon(A)",
    organization: "Organisation",
    endTimes:"Countdown zur ersten Runde",
    getmax:"Get Maximum",
    Authorization:"Genehmigung",
    Subscribe:"Jetzt abonnieren",
    okSubscribe:"Abonniert",
    message:"Knotenfehler, bitte Knoten wechseln",
    end1:"Erste Runde beendet",
    needStart:"Countdown bis zum Start",
    getscusse:"Abruf erfolgreich",
    geterror:"Abruf fehlgeschlagen"

  },
  Withdraw: {
    Withdraw: "Abheben",
    Withdrawable: "Abhebbar",
    Earnings: "Erträge",
    Brokerage: "Maklergebühr",
    Reinvest: "Reinvestieren",
    Getreward: "Belohnung erhalten",
    Processing: "In Bearbeitung",
    unsuccessfule: "Erfolglos",
    successful: "Erfolgreich!",
    WithdrawInfo1: "Bitte geben Sie die Abhebungsmenge ein",
    WithdrawInfo2: "Menge unzureichend",
  },
  Team: {
    innver: "Innver",
    Teamstaked: "Team-Stake",
    leavel: "Stufe",
    Team: "Team",
    Bind: "Verbindung",
    info: "Das System hat vorübergehend die Bindung angehalten. Bitte warten Sie auf die Öffnung",
    Binding: "Bindung Läuft",
    BindingSuccessful: "Bindung Erfolgreich",
    BindingFailed: " Bindung Fehlgeschlagen",
    Bindinfo: "Bitte geben Sie die Wallet-Adresse des Einladers ein",
  },
  Share: {
    Invitelink: "Einladungslink",
    Intips: `Dapp-Einladungen sind derzeit nicht verfügbar. 
            Bitte warten Sie auf die neuesten Community-Updates.`,
    copyTitle: "Einladungslink",
    copyText: "Der Einladungslink wurde erfolgreich kopiert!",
    copyCommnad: "Link kopieren",
  },
  Contract: {
    contractaddress: "Vertragsadresse",
    Copylink: "Polygonscan",
    CopylinkText: "Gehe zu polygonscan!",
  },
  Wathch: {
    watchonlywallet: "Wallet nur beobachten",
    addwallet: "Wallet hinzufügen",
    add: "Hinzufügen",
    Leavel: "Stufe",
    share: "Teilen",
    fnOpen:
      "Dieses Wallet wurde noch nicht zur Teilnahme an dieser Funktion eingeladen!",
    nullString: "Bitte geben Sie die Wallet-Adresse ein!",
    addok:"Erfolgreich hinzugefügt",
    addError:"Hinzufügen fehlgeschlagen",
    addisExists:"Diese Adresse existiert bereits",
    addlist:"Beobachtungsliste",
  },
  
};
export default de;
