import Web3 from "web3";
import big from "big.js";
import { ethers } from "ethers";

export default {
   
    // 
    async swapExactTokens(
      addresscode,
      ContractAddress,
      _decimals,
      swap_Avalue,
      destAmount,
      swap_Atoken,
      swap_Btoken,
      time
    ) {
      try {
        //   let position = "";
        //  console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/swapFalconapi.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        //    console.log(Factory.abi)
 
        // const amountApproved = big(swap_Avalue)
        // .times(10 ** 18)
        // .toFixed();
        console.log(swap_Avalue,destAmount,[swap_Atoken,swap_Btoken],time)
        //   console.log(amountApproved)
        const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
        
        // console.log(tokebContract)
        const approveFunction = tokebContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,swap_Btoken],addresscode,time); // 替换为您要授权的代币数量
        //  console.log(approveFunction)
        // gas
        const getGasPrice=await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        //  预估
        const hexValue = await approveFunction.estimateGas({
          from: addresscode,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
        });
        //   console.log(hexValue);
  
        await tokebContract.methods
          .swapExactTokensForTokensSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,swap_Btoken],addresscode,time)
          .send({
            from: addresscode,
            gasLimit: hexValue,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
          });
  
        return { code: "0" };
      } catch (error) {
           console.log(error);
      }
    },
    // 
    async swapExactTokens2(
      addresscode,
      ContractAddress,
      _decimals,
      swap_Avalue,
      destAmount,
      swap_Atoken,
      DAI_swap,
      swap_Btoken,
      time
    ) {
      try {
        //   let position = "";
        //  console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/swapFalconapi.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        //    console.log(Factory.abi)
 
        // const amountApproved = big(swap_Avalue)
        // .times(10 ** 18)
        // .toFixed();
        console.log(swap_Avalue,destAmount,[swap_Atoken,DAI_swap,swap_Btoken],addresscode,time)
        //   console.log(amountApproved)
        const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
        
        // console.log(tokebContract)
        const approveFunction = tokebContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,DAI_swap,swap_Btoken],addresscode,time); // 替换为您要授权的代币数量
        //  console.log(approveFunction)
        // gas
        const getGasPrice=await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        //  预估
        const hexValue = await approveFunction.estimateGas({
          from: addresscode,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
        });
        //   console.log(hexValue);
  
        await tokebContract.methods
          .swapExactTokensForTokensSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,DAI_swap,swap_Btoken],addresscode,time)
          .send({
            from: addresscode,
            gasLimit: hexValue,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
          });
  
        return { code: "0" };
      } catch (error) {
           console.log(error);
      }
    },
    
    async FeeOnTransferTokens(
      addresscode,
      ContractAddress,
      _decimals,
      swap_Avalue,
      destAmount,
      swap_Atoken,
      DAI_swap,
      swap_Btoken,
      time
    ) {
      try {
        //   let position = "";
        //  console.log(_decimals)
        let Factory;
        Factory = require("../json/pancakeswap/swapFalconapi.json");
        const ethereum = window.ethereum;
        const web3 = new Web3(Web3.givenProvider || ethereum);
        // 创建合约对象
        //    console.log(Factory.abi)
 
        // const amountApproved = big(swap_Avalue)
        // .times(10 ** 18)
        // .toFixed();
        console.log(swap_Avalue,destAmount,[swap_Atoken,swap_Btoken],time)
        //   console.log(amountApproved)
        const tokebContract = new web3.eth.Contract(Factory.abi, ContractAddress);
        
        // console.log(tokebContract)
        const approveFunction = tokebContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,DAI_swap,swap_Btoken],addresscode,time); // 替换为您要授权的代币数量
        //  console.log(approveFunction)
        // gas
        const getGasPrice=await web3.eth.getGasPrice()
        const getGasPriceto = getGasPrice.toString();
        //  预估
        const hexValue = await approveFunction.estimateGas({
          from: addresscode,
          to: ContractAddress,
          data: approveFunction.encodeABI(),
        });
        //   console.log(hexValue);
  
        await tokebContract.methods
          .swapExactTokensForETHSupportingFeeOnTransferTokens(swap_Avalue,destAmount,[swap_Atoken,DAI_swap,swap_Btoken],addresscode,time)
          .send({
            from: addresscode,
            gasLimit: hexValue,
            to: ContractAddress,
            data: approveFunction.encodeABI(),
            gas: hexValue,
            gasPrice: Math.round(getGasPriceto*1.5),
          });
  
        return { code: "0" };
      } catch (error) {
           console.log(error);
      }
    },
 
};
